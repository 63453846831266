





























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import find from 'lodash/find'

import { PPError } from '@/ppapi/PPError'
import { PrintCategory } from '@/models/DashboardMedication'
import User from '@/models/User'
import Address from '@/models/Address'

import MedicationPrintTable from '@/components/medications/MedicationPrintTable.vue'
import Loading from '@/components/Loading.vue'
import PrintPage from '@/components/PrintPage.vue'
import { formatDate } from '@/i18n'
import { CategorizedMeds } from '@/util/categorizeMeds'

@Component({
  components: {
    Loading,
    MedicationPrintTable,
    PrintPage,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('addresses', ['currentShippingAddress']),
    ...mapGetters('medications', ['medsInPrintCategories']),
    ...mapGetters('asyncStatus', ['getError', 'isLoading', 'hasSucceeded']),
  },
})
export default class MedicationsPrintView extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isLoading!: (key: string) => boolean

  me!: User
  currentShippingAddress!: Address
  medsInPrintCategories!: CategorizedMeds<{ category: PrintCategory }>[]
  showPastMedications!: boolean

  created(): void {
    this.$store.dispatch('user/loadMe')
    this.$store.dispatch('medications/load')
  }

  get address() {
    const { city, state, zipcode } = this.currentShippingAddress
    return `${city}, ${state} ${zipcode?.slice(0, 5)}`
  }

  get today() {
    return formatDate(new Date(), 'MMM DD, YYYY')
  }

  get prescribedMeds() {
    return find(this.medsInPrintCategories, { id: PrintCategory.Prescribed })?.meds
  }

  get selfPrescribedMeds() {
    return find(this.medsInPrintCategories, { id: PrintCategory.SelfPrescribed })?.meds
  }

  get asNeededMeds() {
    return find(this.medsInPrintCategories, { id: PrintCategory.AsNeeded })?.meds
  }

  get pausedMeds() {
    return find(this.medsInPrintCategories, { id: PrintCategory.Paused })?.meds
  }

  get loading() {
    return this.isLoading('user/loadMe') || this.isLoading('medications/load')
  }

  get printOnLoad() {
    return this.$route.query.printOnLoad
  }

  @Watch('loading', { immediate: true })
  onLoadingChanged(loading: boolean) {
    if (!loading && this.printOnLoad) {
      setTimeout(() => window.print(), 1000)
    }
  }
}
