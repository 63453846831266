import { render, staticRenderFns } from "./MedicationsPrintView.vue?vue&type=template&id=35d0f068&scoped=true&"
import script from "./MedicationsPrintView.vue?vue&type=script&lang=ts&"
export * from "./MedicationsPrintView.vue?vue&type=script&lang=ts&"
import style0 from "./MedicationsPrintView.vue?vue&type=style&index=0&id=35d0f068&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35d0f068",
  null
  
)

export default component.exports