






















































import { Component, Vue, Prop } from 'vue-property-decorator'
import DashboardMedication from '@/models/DashboardMedication'

import Icon from '@/components/Icon.vue'
import MedImage from '@/components/medications/MedImage.vue'

@Component({
  components: {
    Icon,
    MedImage,
  },
})
export default class MedicationPrintTable extends Vue {
  @Prop(String)
  title?: string

  @Prop({ type: Array, required: true })
  medications!: DashboardMedication[]

  @Prop(Boolean)
  selfPrescribed!: boolean

  @Prop(Boolean)
  paused!: boolean

  @Prop(String)
  description?: string

  directions(med: DashboardMedication): string {
    return med?.primaryPrescription?.directions || ''
  }

  fallbackIcon(med: DashboardMedication): string {
    return med?.inPackets || med.medicationType === 'bottle' ? 'pills' : ''
  }

  packetTimes(med: DashboardMedication) {
    return med?.primaryPrescription?.doses || []
  }

  rxInfo(med: DashboardMedication): string {
    if (med?.selfPrescribed) return `#${med?.primaryPrescription?.rxNumber}`
    return `Rx#${med?.primaryPrescription?.rxNumber}`
  }

  prescriberName(med: DashboardMedication): string {
    if (med?.selfPrescribed) return this.$t('N/A') as string
    return med?.primaryPrescription?.physician?.fullName || ''
  }

  refills(med: DashboardMedication): number {
    return med?.primaryPrescription?.remainingRefills || 0
  }
}
